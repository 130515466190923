<template>
  <gl-modal
    v-bind="$attrs"
    capitalize-title
    class="delete-from-monitoring-modal"
    full-buttons
    submit-title="Delete"
    title="Delete from monitoring"
    width="600"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="onSubmit"
  >
    <div class="gl-modal__row fs-14">
      Are you sure you want to delete this address <strong class="word-break">{{ address }}</strong> from the Monitoring?
    </div>
  </gl-modal>
</template>

<script>
import GlModal from '@/components/gl-modal'

export default {
  components: {
    GlModal,
  },
  inheritAttrs: false,
  props: {
    address: {
      type: String,
      default: '',
      require: true
    },
  },
  methods: {
    onSubmit() {
      this.$emit('submit', this.address)
    },
  },
}
</script>
<style>
.delete-from-monitoring-modal .gl-modal__title {
  font-size: 20px;
  text-transform: unset;
}
.delete-from-monitoring-modal .gl-modal__container {
  padding: 24px;
}
.delete-from-monitoring-modal .gl-form__actions .gl-button {
 height: 38px;
}
@media (max-width: 767px) {
  .add-to-monitoring-modal .gl-form__actions .gl-button {
    height: 34px;
  }
}
</style>