<template>
  <gl-modal
    v-bind="$attrs"
    capitalize-title
    closable
    full-submit
    ok-only
    overflowing
    submit-title="Got it"
    title="Addresses Uploaded"
    width="450"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="$emit('close')"
  >
    <div class="flex align-center mb-3 fs-14">
      <gl-icon
        class="mr-3"
        :height="24"
        name="upload-label"
        :width="24"
      />
      <div>
        {{ data.added }} addresses added
      </div>
    </div>
    <div class="flex align-center mb-3 fs-14">
      <gl-icon
        class="mr-3"
        :height="24"
        name="def-report"
        :width="24"
      />
      <div>
        {{ data.duplicate }} addresses skipped as already have been added
      </div>
    </div>
    <div class="flex align-center warning-text mb-3 fs-14">
      <gl-icon
        class="mr-3"
        :height="24"
        name="warn-report"
        :width="24"
      />
      <div>
        {{ data.notFound }} addresses are not found or invalid
      </div>
    </div>
  </gl-modal>
</template>

<script>
// Components
import GlModal from '@/components/gl-modal'
import GlIcon from '@/components/gl-icon'

export default {
  components: {
    GlIcon,
    GlModal,
  },
  inheritAttrs: false,
  props: {
    data :{
      type: Object,
      default: () => ({})
    },
  },
}
</script>
