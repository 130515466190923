<template>
  <gl-modal
    v-bind="$attrs"
    class="add-to-monitoring-modal"
    full-buttons
    :loading="loading"
    overflowing-form
    :side-valid="addValidate"
    submit-title="Add"
    title="Add to monitoring"
    :width="428"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="onSubmit($event)"
  >
    <div class="tabs-wrapper flex align-end space-around">
      <div
        class="tab tab-item-full flex-1"
        :class="{'tab-active': activeTab === 'single'}"
        @click="handleActiveTab('single')"
      >
        Single address
      </div>
      <div
        class="tab tab-item-full flex-1"
        :class="{'tab-active': activeTab === 'bulk'}"
        @click="handleActiveTab('bulk')"
      >
        Bulk upload
      </div>
    </div>
    <GlCoinSelect
      dark
      label="blockchain"
    />
    <div
      v-if="activeTab === 'single'"
      class="mb-4"
    >
      <GlInput
        v-model.trim="address"
        class="mb-5"
        :disabled="loading"
        :height="35"
        label="address"
        placeholder="Enter the address"
        rules="required"
      />
    </div>
    <div
      v-else
      class="mb-4 flex space-between"
    >
      <gl-menu-item
        v-if="!file"
        class="add-to-monitoring-modal__download-item fs-14"
        :disabled="loading"
        icon="download"
        :icon-height="20"
        :icon-width="20"
        label="Upload CSV"
        @click="openFileInput"
      />
      <gl-menu-item
        v-else
        class="fs-14 mb-4"
        :disabled="loading"
        icon="file"
        :icon-height="24"
        :icon-width="24"
        :label="file.name || ''"
      />
      <gl-menu-item
        v-if="file"
        :disabled="loading"
        icon="delete-action"
        :icon-height="24"
        :icon-width="24"
        @click="clearFile"
      />
      <input
        ref="addressMonitoringUploader"
        accept=".csv"
        :disabled="loading"
        :style="{display: 'none'}"
        type="file"
        @input="onFileChange"
      >
    </div>
  </gl-modal>
</template>

<script>
// Components
import GlModal from '@/components/gl-modal'
import GlInput from '@/components/gl-input'
import GlMenuItem from '@/components/gl-menu-item'
import GlCoinSelect from "@/components/gl-coin-select.vue";

// Vuex
import { mapActions, mapState } from "vuex";

// Utils
import apiService from "@/utils/api-client";
import {isExceptionStatus} from "@/utils/accesses";
import { trancateString } from '@/utils/text-formatter'

export default {
  components: {
    GlModal,
    GlMenuItem,
    GlCoinSelect,
    GlInput,
  },
  inheritAttrs: false,
  data() {
    return {
      address: '',
      file: '',
      formData: {},
      activeTab: 'single',
      checkLastTen: false,
      loading: false,
    }
  },
  computed: {
    ...mapState('analytics', ['coinData']),
    addValidate() {
      return (this.address.length === 0 && this.activeTab === 'single')
          || (!this.file && this.activeTab === 'bulk')
    }
  },
  methods: {
    ...mapActions({
      addToMonitoring: 'monitoring/addToMonitoring',
      getMe: 'user/getMe'
    }),
    trancateString,
    handleActiveTab(tab) {
      if (this.loading) return
      this.activeTab = tab
      this.address = ''
    },
    openFileInput() {
      this.$refs.addressMonitoringUploader.click()
    },
    onFileChange(e) {
      if (this.loading) return
      this.file = e.target.files[0]

      this.formData = new FormData()

      this.formData.append('file', this.file);
      this.formData.append('blockchain', this.coinData.label);
      this.formData.append('formula', localStorage.getItem('scoringFormula'));
    },
    clearFile() {
      this.file = null
      this.formData = null
    },
    onSubmit() {
      if (this.address || this.file) {
        this.loading = true
        if (this.activeTab === 'single') {

          if (!new RegExp(this.coinData.addressRegex).test(this.address)) {
            this.loading = false
            this.$toasted.global.error({ message: `Invalid address format for the selected network. Please check the address and try again.` })
            return
          }

          this.addToMonitoring({
            address: this.address,
            checkLastTen: this.checkLastTen,
            blockchain: this.coinData.label,
          }).then(({ success, data }) => {
            if (success) {
              this.$emit('add')
              this.$emit('close')
            }
            else if (!isExceptionStatus(data.status)) {
              this.$toasted.global.error({message: `${data.message}`})
            }
          }).catch(({ response: { data } }) => {
              this.$toasted.global.error({message: `${data.data.message}`})
          }).finally(() => {
            this.getMe()
            this.loading = false
          })
        } else {
          apiService.post( '/monitoring/addressToMonitor',
              this.formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }
          ).then(({ success, data }) => {
            if (success) {
              this.$emit('add-bulk', data)
              this.$emit('close')
            }
            else {
              this.$toasted.global.error({message: `${data.message}`})
            }
          }).finally(() => {
            this.getMe()
            this.loading = false
          })
        }
      }
    },
  },
}
</script>
<style>
.add-to-monitoring-modal .gl-modal__title {
  font-size: 20px;
  text-transform: unset;
  margin-bottom: 19px;
}
.add-to-monitoring-modal .gl-modal__container {
  padding: 24px;
  padding-top: 22px;
}

.add-to-monitoring-modal .tabs-wrapper {
  margin-bottom: 22px;
}
.add-to-monitoring-modal .tab {
  font-size: 14px;
}
.add-to-monitoring-modal .gl-input__label {
  margin-bottom: 4px;
}
.add-to-monitoring-modal .gl-input__error {
  top: 6px;
}
.add-to-monitoring-modal .coin-select-base {
  margin-right: 0 !important;
  margin-bottom: 22px;
}
.add-to-monitoring-modal .gl-form__actions .gl-button {
 height: 38px;
}

.add-to-monitoring-modal__download-item .gl-menu-item {
  padding: 0;
  margin-bottom: 21px;
}
.add-to-monitoring-modal__download-item .gl-menu-item__label {
  font-weight: 600;
  color: var(--accent-reflex-blue);
}
.add-to-monitoring-modal__download-item .gl-menu-item__icon  {
  margin-right: 6px;
}
@media (max-width: 767px) {
  .add-to-monitoring-modal .gl-form__actions .gl-button {
    height: 34px;
  }
}
</style>
